
const Intro = () => {
  const sms1 = ['NOS', 'COMEMOS', 'EL COCO', 'X TÍ'];
  const sms2 = ['ESTUDIO', 'DISEÑO', 'GRÁFICO', 'EN CÁDIZ'];
  return (
    <>
      <div className="Intro">
        <div className="container sentenceContainer">
          <div className="gapSentence sentence1 extrabold">{sms1 && sms1.map((item) => <span key={item} className="particle">{item}</span>)}</div>
          <div className="gapSentence sentence2 regular">{sms2 && sms2.map((item) => <span key={item} className="particle">{item}</span>)}</div>
        </div>
      </div>
      <style jsx>{`
        .Intro {
          padding:0px var(--padding-container);
        }
        .Intro .sentenceContainer {
          display: flex;
          flex-direction:row;
          width: 100%;
          flex-wrap: wrap;
          justify-content:space-between;
          align-items:center;
        }
        .Intro .sentenceContainer  .gapSentence {
          display:flex;
          flex-direction:column;
          width:50%;
          align-items: flex-start;
          justify-content: space-around;
        }
        .Intro .sentenceContainer  .gapSentence.sentence1 {
          align-items: flex-start;    
        }
        .Intro .sentenceContainer  .gapSentence.sentence1 .particle{
          font-size:2.5rem;
          line-height:2.5rem;
        }
        .Intro .sentenceContainer  .gapSentence.sentence2 {
          align-items: flex-end;
        }
        .Intro .sentenceContainer  .gapSentence.sentence2 .particle{
          font-size:1rem;
          padding:0.15rem;
        }

    `}</style>
    </>
  )
}

export default Intro;